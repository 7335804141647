import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pantalla-carga',
  templateUrl: './pantalla-carga.component.html',
  styleUrls: ['./pantalla-carga.component.css']
})
export class PantallaCargaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
