<div class="ajustar">
  <p class="titulo">Si esta de acuerdo con las condiciones del crédito y desea firma su pagaré y su carta de
    instrucciones digite el código OTP que le hemos enviado</p>

  <form class="login100-form validate-form" ngNativeValidate #formLogin="ngForm" (ngSubmit)="firmar()"
        autocomplete="off">
    <p class="txtForm">Código OTP</p>
    <input class="form-control inputloginmb-3" type="text" placeholder="Ingresse aquí el código que recibió"
           name="codigo" required="true" #xCodigo="ngModel" (ngModel)="xCodigo"/>
    <div class="col-12">
      <div class="col-6 ajustarColum">
        <input type="checkbox"/><a class=" TextoSimpleBlanco float-right"> He leido y estoy de acuerdo</a>
      </div>
        <button class="btn btn-primary btn-lg btnRegresar" id='btnContinue' type="submit"
                name='btnContinue' value="Send"><b>Firmar</b></button>
    </div>
  </form>
</div>
