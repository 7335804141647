import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { RespuestaGenerica } from '../../Model/Response/RespuestaGenerica';
import { environment } from '../../../environments/environment';
import { ResponseSolicitudPagare } from '../../Model/Response/response-solicitud-pagare';
import { ResponseDetalleFirmaPagare } from '../../Model/Response/response-detalle-firma-pagare';
import { ResponseObtenerDocumento, ResponseObtenerMultiplesDocs } from '../../Model/Response/response-obtener-documento';
import { SolicitudPagare } from '../../Model/solicitud-pagare';
import { RequestCrearPagare } from 'src/app/Model/request/request-crear-pagare';

@Injectable({
  providedIn: 'root'
})
export class GestorSolPagareService {
  public url = environment.URL_SERVICE + 'GestionPinc';

  public headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      // "Authorization": "Bearer" + sessionStorage.getItem("token")
    }
  );

  constructor(private http: HttpClient) { }

  obtenerSolicitudesPagare() {
    const path = this.url + '/SolicitudesPagare/obtenerSolicitudesPagare';
    return this.http.post<ResponseSolicitudPagare>(path, { headers: this.headers });
  }

  cargarEstadoPagare(xiIdProceso: string) {
    let path = this.url + '/SolicitudesPagare/obtenerEstadoFirmaDocumento';
    path += '?xiIdProceso=' + xiIdProceso;
    return this.http.post<RespuestaGenerica>(path, { headers: this.headers });
  }

  obtenerDetalleFirmaDocumento(xiIdProceso: string) {
    let path = this.url + '/obtenerDetalleFirmaDocumento';
    path += '?xiIdProceso=' + xiIdProceso;
    return this.http.post<ResponseDetalleFirmaPagare>(path, { headers: this.headers });
  }

  obtenerMultiplesDocs(solicitudPagare: SolicitudPagare) {
    let path = this.url + "/obtenerMultiplesDocs";
    return this.http.post<ResponseObtenerMultiplesDocs>(path, solicitudPagare, { headers: this.headers });
  }

  crearSolicitudPagare(requestCrearPagare: RequestCrearPagare) {
    let path = this.url + '/CrearSolicitudPagare';
    return this.http.post<RespuestaGenerica>(path, requestCrearPagare, { headers: this.headers });
  }

  savaSolicitudPagare(tipoIdent: string, Ident: string, xiTipoPerfil: number, reqCodeudor: string) {
    let path = this.url + '/CrearSolicitudPagare';
    path += '?xiTipoIdent=' + tipoIdent;
    path += '&xcIdent=' + Ident;
    path += '&xiTipoPerfil=' + xiTipoPerfil;
    path += '&xcReqCodeudor=' + reqCodeudor;
    return this.http.post<RespuestaGenerica>(path, { headers: this.headers });
  }

  reenviarOTPSolPagare(solicitudPagare: SolicitudPagare) {
    const path = this.url + '/reenviarOTPSolPagare';
    return this.http.post<RespuestaGenerica>(path, solicitudPagare, { headers: this.headers });
  }

  obtenerDocumentoPagare(solicitudPagare: SolicitudPagare) {
    const path = this.url + '/obtenerDocumentoPagare';
    return this.http.post<ResponseObtenerDocumento>(path, solicitudPagare, { headers: this.headers });
  }

}

