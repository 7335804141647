<div class="row col-md-12 ml-5 mt-1 pt-1 responsiveContent">
    <div class="col-md-6 card-credito">
        <div class="p-4">
            <div class="card-body pt-3">
                <div class="row">
                    <div class="col-12 ">
                        <h3 class="txtBienvenidoSubtitulo">
                            ¿Cómo te podemos ayudar hoy?
                        </h3>
                    </div>
                </div>
                <div class="row  centro justify-content-center contenedorbotones">
                    <div class="centro">
                        <div class="col-12">
                            <button class="btn btnInformacion" (click)="irPagares()"> Pagaré</button>
                        </div>
                        <div class="col-12">
                            <button class="btn btnInformacion" (click)="irSeguridad()">Reiniciar Clave</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-5  ml-3 pt-4">
        <div class="formulario-scroll">
          <div class="col-6">
            <img src="assets/Imagenes/admin.png" class="imagen"/>
          </div>
        </div>
      </div>
</div>
