import { Component, OnInit } from '@angular/core';

import { TokenImpService } from '../../../Services/ImplementacionServicios/token-imp';
import { LoginServiceImp } from '../../../Services/ImplementacionServicios/login-service-imp';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-menu-cliente',
  templateUrl: './menu-cliente.component.html',
  styleUrls: ['./menu-cliente.component.css']
})
export class MenuClienteComponent implements OnInit {

  public nombre: string;

  constructor(
    private http: HttpClient,
    private token: TokenImpService,
    private loginSer: LoginServiceImp,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.nombre = this.obtenerNombre();
  }

  obtenerNombre() {
    return this.token.obtenerNombres();
  }

  irInicio() {
    this.router.navigate(['PincWeb/Cliente/homeCliente']);
  }

  goMisPagares() {
    this.router.navigate(['PincWeb/Cliente/MisPagares']);
  }

  cerrarSesion() {
    this.messageService.clear();
    this.messageService.add({ key: 'cs', sticky: true, severity: 'warn', summary: '¿Está seguro de cerrar la sesión?', detail: 'Por favor confirmar el cierre de sesión' });
  }

  confirmarCierreSesion() {
    this.SpinnerService.show("sp6");
    let codigoUsuario = this.token.getCodUser();
    this.loginSer.cerrarSession(codigoUsuario.toString()).then(
      (response: any)=>{
        if (response.bRta) {
          this.token.clearStorage();
          this.router.navigate(['PincWeb/sesion/login']);
        }
        else{
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Alerta!', detail: response.sMsj });
        }
      }
    );   
  }

  cancelarCierreSesion() {
    this.messageService.clear('cs');
  }
}
