import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenImpService } from '../../../Services/ImplementacionServicios/token-imp';

@Component({
  selector: 'app-home-cliente',
  templateUrl: './home-cliente.component.html',
  styleUrls: ['./home-cliente.component.css']
})
export class HomeClienteComponent implements OnInit {

  public nombres: string;


  constructor(
    private token: TokenImpService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.nombres = this.obtenerNombre();
  }


  obtenerNombre() {
    return this.token.obtenerNombres();
  }

  goMisPagares() {
    this.router.navigate(['PincWeb/Cliente/MisPagares']);
  }

}
