<ngx-spinner name="sp6" type="timer" size="medium">
    <p style="color: white"> Espere unos segundos... </p>
  </ngx-spinner>
  <p-toast key="msgToast" position="top-right"></p-toast>
  <div class="row col-md-12 ml-5 mt-1 pt-1 responsiveContent">
    <div class="col-md-6 card-credito">
      <div class="p-4">
        <div class="card-body pt-3">
          <p class="titulo">Por favor ingrese la información para resturar la clave.</p>
          <form class="login100-form validate-form" ngNativeValidate #formOlvidoClave="ngForm"
            (ngSubmit)="restaurarClave(formOlvidoClave)" autocomplete="off">
            <p class="txtForm">Tipo de perfil:</p>
            <select name="tipoPerfil" name="tipoPerfil" class="form-control" #selectedTipoPerfil="ngModel"
              (ngModel)="selectedTipoPerfil">
              <option value="" disabled>Selecciona tu tipo de perfil</option>
              <option value="{{item.ICodigo}}" *ngFor="let item of listPerfilCiente" class="form-control">
                {{item.CDescripcion}}
              </option>
            </select>
            <p class="txtForm">Tipo de identificación:</p>
            <select name="tipoIdent" name="tipoIdent" class="form-control" #selectedTipoident="ngModel"
              (ngModel)="selectedTipoident">
              <option value="" disabled>Selecciona tu tipo de identificación</option>
              <option value="{{item.ICodigo}}" *ngFor="let item of listTipoIdent" class="form-control">
                {{item.CDescripcion}}
              </option>
            </select>
            <p class="txtForm">Usuario:</p>
            <input class="form-control inputloginmb-3" type="text" placeholder="Usuario" name="usuario" required="true"
              #xUsuario="ngModel" (ngModel)="xUsuario" />
            <a class="TextoSimpleBlanco float-right" (click)="volverInicioSesion()"> Iniciar sesión</a>
            <button pButton pRipple label="Restaurar clave" class="centro" id="btnContinue" name="btnContinue"
              icon="pi pi-check" type="submit"></button>
            <div class="col-6 marca">
              <a href="https://www.ebssas.com/" target="_blank">
                <div class="row">
                  <div class="col-8" style="padding: 0;">
                    <legend class="MarcaEBS">Desarrollado por EBS</legend>
                  </div>
                </div>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-5  ml-3 pt-4">
      <div class="formulario-scroll">
        <div class="col-6">
          <img src="assets/Imagenes/cliente.png" class="imagen" />
        </div>
      </div>
    </div>
  </div>
