import { PersonaRe, PersonaCodeudor } from "../DTO/PersonaRegistry";

export class RequestCrearPagare {
    iTipoPerfilDeudor?: number;
    personaDeudor?: PersonaRe;
    iTipoPerfilCodeudor?: number[];
    personaCodeudor?: PersonaRe[];
    requiereCodeudor?: boolean;
    ciudadCodeudor?: number[];
    ciudadDeudor?: number;
    numPagare?: number;
}

