import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginServiceImp } from '../../../Services/ImplementacionServicios/login-service-imp';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../pages/dialog/dialog.component';
import { TokenImpService } from '../../../Services/ImplementacionServicios/token-imp';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoPerfil, RespuestaTipoPerfil } from '../../../Model/Response/RespuestaTipoPerfil';
import { TipoIdentificacion, RespuestaTipoIdent } from '../../../Model/Response/RespuestaTipoIdent';
import { CargaDatosImpService } from '../../../services/ImplementacionServicios/carga-datos-imp';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public listPerfilCiente: TipoPerfil[];
  public listTipoIdent: TipoIdentificacion[];
  constructor(
    private route: Router,
    private loginServiceImp: LoginServiceImp,
    private SpinnerService: NgxSpinnerService,
    private jwt: TokenImpService,
    private cargaDatosServiceImp: CargaDatosImpService,
    private dialog: MatDialog,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.SpinnerService.hide();
    this.jwt.clearStorage();
    this.obtenerPerfilesClientes();
    this.obtenerTiposIdent();
  }

  obtenerPerfilesClientes() {
    this.SpinnerService.show("sp6");
    this.cargaDatosServiceImp.tipoPerfilCliente().then(
      (response: RespuestaTipoPerfil) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listPerfilCiente = response.listaTipoPerfil;
        } else {
          this.SpinnerService.hide("sp6");
        }
      }
    )
  }

  obtenerTiposIdent() {
    this.SpinnerService.show("sp6");
    this.cargaDatosServiceImp.tiposIden().then(
      (response: RespuestaTipoIdent) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listTipoIdent = response.listaTipoIdent;
        } else {
          this.SpinnerService.hide("sp6");
        }
      }
    )
  }

  iniciarSesion(iTipoPerfil: number, iTipoIdent: any, ident: any, clave: any) {
    this.SpinnerService.show("sp6");
    try {
      this.loginServiceImp.loginCliente(iTipoPerfil, iTipoIdent, btoa(ident), btoa(clave)).then(
        (data: any) => {
          if (data == "Fallo") {
            this.SpinnerService.hide("sp6");
            this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Error!', detail: "Se ha presentando un inconveniente inesperado." });
          } else {
            this.jwt.guardarToken(data.jwt);
            this.jwt.guardarDatosUsuario(ident);
            if (!this.jwt.getbRta()) {
              this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: this.jwt.getsMsj() });
              this.SpinnerService.hide("sp6");
              this.jwt.clearStorage();
            } else {
              this.messageService.add({ key: 'msgToast', severity: 'info', summary: '¡Bienvenido!', detail: this.jwt.getsMsj() });
              this.SpinnerService.hide("sp6");
              let ultimaConexion = this.jwt.obtenerUltimaConexion();
              if (ultimaConexion != null && ultimaConexion != undefined && ultimaConexion != "" && ultimaConexion == "N/A") {
                this.route.navigate(['PincWeb/Cliente/cambioClaveInicial']);  
              } else {
                this.route.navigate(['PincWeb/Cliente/homeCliente']);
              }
              
            }
          }
        },
        (error: HttpErrorResponse) => {
          this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un problema de conexión' });
          this.SpinnerService.hide("sp6");
        })

    } catch (error) {
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un inconveniente inesperado.' });
      this.jwt.clearStorage();
    }

  }

  async validarlogin(form: NgForm) {
    if (form.valid) {
      let iTipoPerfil = form.form.value.tipoPerfil;
      let iTipoIdent = form.form.value.tipoIdent;
      let ident = form.form.value.usuario;
      let clave = form.form.value.clave;
      this.SpinnerService.show("sp6");
      if (iTipoPerfil != null && iTipoPerfil != "" && iTipoIdent != null && iTipoIdent != "") {
        if (ident != null && ident != "" && clave != null && clave != "") {
          this.iniciarSesion(iTipoPerfil, iTipoIdent, ident, clave);
        } else {
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'Por favor verifique el fomrulario, debe ingresar información de usuario y clave.' });
          this.SpinnerService.hide("sp6");
        }
      } else {
        this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'Por favor verifique el fomrulario, debe ingresar información de tipo de perfil y tipo de identificación.' });
        this.SpinnerService.hide("sp6");
      }
    } else {
      this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'No se ha validado el formulario correctamente.' });
      this.SpinnerService.hide("sp6");
    }
  }

  abrirDialog(xTitulo: string, xMensaje: string, tipoDialog: string, redireccion: string) {
    let dataC = {
      titulo: xTitulo,
      texto: xMensaje,
      tipoDialog: tipoDialog,


    };
    let dialogRef = this.dialog.open(DialogComponent, { data: dataC, panelClass: 'prueba' });
    dialogRef.afterClosed().subscribe(
      (result: any) => {
        if (redireccion == "N/A") {
          this.route.navigate(['/cambiarClave']);
        } else if (redireccion == "inicio") {
          this.route.navigate(['session/login']);
        } else {
          this.route.navigate(['sesion/bienvenido']);
        }
      }
    )
  }

  cerrarVentanaEmergente() {
    this.SpinnerService.hide();
  }

  CambiarClave() {
    this.route.navigate(['PincWeb/sesion/olvidoClave']);
  }
}
