<div class="boxAutorizacion">
  <table>
    <tr>
      <td>
        <!--Sección 1-->
        <input class="form-control inputloginmb-3" type="text" placeholder="Nombre" name="Nombre" required="true" />
        <p class="txtAutorizacion">
          , mayor de edad, identificado(a) con la cédula de ciudadanía No.                </p>
        <input class="form-control inputloginmb-3" type="text" placeholder="Cédula" name="Cedula" required="true" />
        <p class="txtAutorizacion">expedida en</p>
        <input class="form-control inputloginmb-3" type="text" placeholder="Ciudad" name="Ciudad" required="true" />
        <p class="txtAutorizacion">
          obrando en nombre y representación de</p>
        <input class="form-control inputloginmb-3" type="text" placeholder="organización" name="organizacion" required="true" />
        <p class="txtAutorizacion">
          , con NIT</p>
        <input class="form-control inputloginmb-3" type="text" placeholder="NIT" name="NIT" required="true" />
        <p class="txtAutorizacion">
          , como su representante
          legal (o en nombre propio, según sea el caso), con domicilio en la ciudad de</p>
        <input class="form-control inputloginmb-3" type="text" placeholder="Ciudad" name="Ciudad2" required="true" />
        <p class="txtAutorizacion">
          , en adelante EL DEUDOR, manifiesto:
          PRIMERO. EL DEUDOR se obliga a pagar en dinero efectivo, de manera incondicional, solidaria e indivisible a la orden de
        </p>
        <input class="form-control inputloginmb-3" type="text" placeholder="Campo" name="Campo" required="true" />
        <p class="txtAutorizacion">
          , identificado con NIT</p>
        <input class="form-control inputloginmb-3" type="text" placeholder="NIT" name="NIT2" required="true" />
        <p class="txtAutorizacion">
          , en adelante EL ACREEDOR, o a quien represente sus
          derechos o a quien en el futuro ostente dicha calidad, en sus oficinas ubicadas en la ciudad de </p>
        <input class="form-control inputloginmb-3" type="text" placeholder="Ciudad" name="Ciudad3" required="true" />
        <p class="txtAutorizacion">
          , la suma de </p>
        <input class="form-control inputloginmb-3" type="text" placeholder="$" name="suma" required="true" />
        <p class="txtAutorizacion">
          moneda corriente </p>
        <input class="form-control inputloginmb-3" type="text" placeholder="$" name="moneda" required="true" />
        <p class="txtAutorizacion">
          por concepto de capital, y la suma de</p>
        <input class="form-control inputloginmb-3" type="text" placeholder="$" name="suma2" required="true" />
        <p class="txtAutorizacion">
          moneda corriente $</p>
        <input class="form-control inputloginmb-3" type="text" placeholder="$" name="moneda2" required="true" />
        <p class="txtAutorizacion">
          por concepto de intereses remuneratorios causados hasta la fecha, liquidados a la tasa efectiva anual de </p>
        <input class="form-control inputloginmb-3" type="text" placeholder="%" name="anio" required="true" />
        <p class="txtAutorizacion">
          . En caso de mora pagaré, por cada día de retardo, intereses liquidados a la tasa máxima legal permitida. Sobre los intereses vencidos se
          podrán liquidar intereses dentro de los límites que establezca la ley. De igual manera me obligo a pagar todos los gastos y
          costos de la cobranza prejudicial, judicial y extrajudicial, incluidos los honorarios de abogado. SEGUNDO. EL ACREEDOR podrá
          declarar, en cualquier tiempo, de plazo vencido todas las obligaciones a nuestro cargo sin necesidad de aviso o requerimiento
          alguno, y podrá llenar el pagaré en cualquiera de los siguientes eventos: a) Por el incumplimiento o retardo en el pago de
          cualquier obligación que haya adquirido EL DEUDOR directa o indirectamente; en cuyo caso, podrá EL ACREEDOR exigir el pago de
          la totalidad de las obligaciones a cargo de EL DEUDOR; b) Por falta de traslado y pago oportuno de capital por las primas y/o
          comisiones, intereses, u otros valores que se deriven de cualquier forma de contratos celebrados con EL ACREEDOR. c)
          Incumplimiento de cualquier obligación contractual a cargo de EL DEUDOR. d) En caso de retener, retardar o desviar,
          directamente o a través de terceros, cualquier suma de dinero de clientes. e) Cuando EL DEUDOR entre en estado de cesación
          de pagos, o se encuentre en estado de insolvencia manifiesta, o le sea embargado algún activo de su propiedad en proceso legal.
          f) En caso de inexactitud en balances, informes, declaraciones o documentos o en caso de devolución de cheque por ausencia
          total o parcial de fondos, si fuere el caso; g) Cualquier causal establecida en la ley o en contrato suscrito con EL ACREEDOR.
          TERCERO. Facultamos expresa e irrevocablemente a EL ACREEDOR para compensar contra el valor de las comisiones o cualquier
          otra suma de dinero a favor de EL DEUDOR, el valor total o parcial del pagaré, así como el valor de los intereses, gastos de
          cobranza, honorarios de abogado y todos los demás exigibles. CUARTO. Autorizamos permanente e irrevocablemente a EL ACREEDOR o
          a quien este delegue, o a quien en el futuro le sean cedidos sus derechos u ocupen su misma posición para
          que consulte, procese, reporte, suministre, retire y actualice información a cualquier Central de
          Información debidamente constituida. QUINTO. Serán de cargo de EL DEUDOR los impuestos, gravámenes,
          retenciones o descuentos que afecten la obligación, lo mismo que los gastos de la cobranza, prejudicial
          y judicial, cuando a ello hubiere lugar. SEXTO. Declaro excusado el protesto del presente pagaré.
        </p>
        <br>
        <!-- <button type="button" class="btn btn-autorizacion" (click)="parte1()">Autorizo</button> -->
      </td>
    </tr>
  </table>
</div>
