import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//COMPONENTES
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Componente de inicio sesion
import { PincWebComponent } from './PincWeb/pincweb.component';
import { SesionComponent } from './PincWeb/sesion/sesion.component';
import { LoginComponent } from './PincWeb/sesion/login/login.component';
import { LoginAdminComponent } from './PincWeb/sesion/login-admin/login-admin.component';
import { OlvidoClaveClienteComponent } from './PincWeb/sesion/olvido-clave-cliente/olvido-clave-cliente.component';
import { OlvidoClaveEmpleadoComponent } from './PincWeb/sesion/olvido-clave-empleado/olvido-clave-empleado.component';

// Componene de menu
import { DialogComponent } from './PincWeb/pages/dialog/dialog.component';
import { PantallaCargaComponent } from './PincWeb/pages/pantalla-carga/pantalla-carga.component';
import { MenuClienteComponent } from './PincWeb/pages/menu-cliente/menu-cliente.component';
import { MenuAdminComponent } from './PincWeb/pages/menu-admin/menu-admin.component';
import { MenuComponent } from './PincWeb/pages/menu/menu.component';
import { FooterComponent } from './PincWeb/pages/footer/footer.component';

// Componente del gestor
import { GestionComponent } from './PincWeb/Gestion/gestion.component';
import { GestionUsuariosComponent } from './PincWeb/Gestion/gestion-usuarios/gestion-usuarios.component';
import { DetallesUsuarioComponent } from './PincWeb/Gestion/detalles-usuario/detalles-usuario.component';
import { GestionPagareComponent } from './PincWeb/Gestion/gestion-pagare/gestion-pagare.component';
import { HomeAdminComponent } from './PincWeb/Gestion/home-admin/home-admin.component';
import { ReiniciarClaveComponent } from './PincWeb/Gestion/reiniciar-clave/reiniciar-clave.component';


// Componentes del cliente
import { ClienteComponent } from "./PincWeb/Cliente/cliente.component";
import { CompletarFirmarComponent } from './PincWeb/Cliente/completar-firmar/completar-firmar.component';
import { DocumentoComponent } from './PincWeb/Cliente/documento/documento.component';
import { FirmaComponent } from './PincWeb/Cliente/firma/firma.component';
import { HomeClienteComponent } from './PincWeb/Cliente/home-cliente/home-cliente.component';
import { MisPagaresComponent } from './PincWeb/Cliente/mis-pagares/mis-pagares.component';
import { CambioClaveInicialComponent } from './PincWeb/Cliente/cambio-clave-inicial/cambio-clave-inicial.component';

// MATERIAL
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon/';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';

// PRIMENG
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { TabViewModule } from 'primeng/tabview';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import {CardModule} from 'primeng/card';


//BOOTSTRAP
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MessageService } from 'primeng/api';


@NgModule({
  declarations: [
    AppComponent,
    PincWebComponent,
    SesionComponent,
    MenuComponent,
    FooterComponent,
    LoginComponent,
    LoginAdminComponent,
    GestionComponent,
    ClienteComponent,
    DialogComponent,
    PantallaCargaComponent,
    MenuAdminComponent,
    CompletarFirmarComponent,
    DocumentoComponent,
    FirmaComponent,
    GestionUsuariosComponent,
    DetallesUsuarioComponent,
    GestionPagareComponent,
    HomeAdminComponent,
    HomeClienteComponent,
    MisPagaresComponent,
    MenuClienteComponent,
    OlvidoClaveClienteComponent,
    OlvidoClaveEmpleadoComponent,
    CambioClaveInicialComponent,
    ReiniciarClaveComponent,
  ],
  imports: [
    BrowserModule,
    MatSliderModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    InputSwitchModule,
    ProgressBarModule,
    InputTextareaModule,
    CalendarModule,
    ToolbarModule,
    TabViewModule,
    CheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatIconModule,
    HttpClientModule,
    DialogModule,
    MatDialogModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    RadioButtonModule,
    NgxSpinnerModule,
    KeyFilterModule,
    MatStepperModule,
    TableModule,
    MessagesModule,
    MessageModule,
    CardModule,
    ToastModule
  ],
  entryComponents: [
    DialogComponent,
    MatTableModule
  ],
  providers: [
    LoginAdminComponent,
    LoginComponent,
    MessageService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
