import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {CargarTodosUsuariosImpService} from 'src/app/services/ImplementacionServicios/cargar-todos-usuarios-imp';

@Component({
  selector: 'app-gestion-usuarios',
  templateUrl: './gestion-usuarios.component.html',
  styleUrls: ['./gestion-usuarios.component.css']
})
export class GestionUsuariosComponent implements OnInit {

  displayedColumns: string[] = ['nombres', 'estado', 'acciones'];

  public dataSource =[
    {CNombres: 'Camilo Pardo', CEstado: 'Activo'},
    {CNombres: 'Sofia Rodriguez', CEstado: 'Activo'},
    {CNombres: 'Laura Diaz', CEstado: 'Activo'},
    {CNombres: 'Daniel Cardenas', CEstado: 'Activo'}];

  public linea: any[] = [];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];

  // MatPaginator Output
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public asociadoSelected:any;


  constructor(private route: Router, private spinner: NgxSpinnerService, private dialog: MatDialog, private CargarTodosUsuarios: CargarTodosUsuariosImpService) { }

  ngOnInit(): void {
    this.cargarDatos();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cargarDatos(){

    // this.spinner.show("sp6");
    // this.CargarTodosUsuarios.cargarUsuarios().then((data: any) => {
    //   if (data != null) {
    //     //  this.datosPersona = data;
    //     console.log(data);
    //     this.dataSource = new MatTableDataSource(data);
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.sort = this.sort;
    //   }
    //   this.spinner.hide("sp6");
    // });
    // var response = JSON.parse('[{"sNombreEmpleado":"Alberto casillas", "Area":"Contable", "Permisos":"Absolutos","acciones":"Hacer popo"},{"sNombreEmpleado":"Alberto casillas", "Area":"Contrable", "Permisos":"Absolutos","acciones":"Hacer popo"}]');
    // let arregloPersonas:any[]=[];
    // for (let i = 0; i < 10; i++) {
    //   let datos = {iCodigo:i,identi:i*999,sNombreEmpleado:"Alberto casillas "+i,tipo:"ATC",estado:"activo"};
    //   arregloPersonas.push(datos);
    // }
  }

  infoAsociado(event){
    console.log(event);
    this.asociadoSelected=event;
    this.route.navigate(['/admin/gestion-usuarios/detalles-usuario']);
  }

  editarAsociado(event){
    this.route.navigate(['admin/infoAsociado',event.iCodigo]);

  }
}
