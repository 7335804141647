import { Injectable } from '@angular/core';
import { TokenService } from '../ConsumoServicios/token.service';

@Injectable({
  providedIn: 'root'
})
export class TokenImpService {

  constructor(private token: TokenService) { }


  guardarToken(token: string) {
    sessionStorage.setItem("sesionAsociado", token);
  }

  guardarTokenAdmin(token: string) {
    sessionStorage.setItem("sesionAdmin", token);
  }

  guardarDatosUsuario(ident: string) {
    sessionStorage.setItem("identUsu", btoa(ident));
  }

  getToken(): string {
    let token = sessionStorage.getItem("sesionAsociado");
    if (token != undefined && token != null && token != "") {
      return token;
    } else {
      return "";
    }
  }

  getTokenEmpleado(): string {
    let token = sessionStorage.getItem("sesionAdmin");
    if (token != undefined && token != null && token != "") {
      return token;
    } else {
      return "";
    }
  }

  jwtDecode(token: string): string {
    let spliToken = token.split(".");
    let decodeToken = window.atob(spliToken[1]);
    return decodeToken;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = this.jwtDecode(token);
    const valorToken = JSON.parse(decoded);

    if (valorToken.exp === undefined) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(valorToken.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }
    if (!token) {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('sesionAsociado');
    return !this.isTokenExpired(token);
  }

  datosLogin(): string {
    let datosLoginSucces: string;
    let token: string = this.getToken();    
    if (token != "") {
      let spliToken = token.split(".");      
      datosLoginSucces = window.atob(spliToken[1]);
    } else {
      datosLoginSucces = "";
    }
    return datosLoginSucces;
  }

  datosLoginEmpleado(): string {
    let datosLoginSuccesEmpleado: string;
    let token: string = this.getTokenEmpleado();
    if (token != "") {
      let spliToken = token.split(".");
      datosLoginSuccesEmpleado = window.atob(spliToken[1]);
    } else {
      datosLoginSuccesEmpleado = "";
    }
    return datosLoginSuccesEmpleado;
  }

  getCodPersona(): number {
    let codPerson: number;
    let datosLogin = this.datosLogin();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      codPerson = valorToken.valToken.codPersona;
    } else {
      codPerson = 0;
    }
    return codPerson;
  }
  
  getCodPersonaEmpleado(): number {
    let codPerson: number;
    let datosLogin = this.datosLoginEmpleado();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      codPerson = valorToken.valToken.codPersona;
    } else {
      codPerson = 0;
    }
    return codPerson;
  }

  getCodUser(): number {
    let iCodUser: number;
    let datosLogin = this.datosLogin();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      iCodUser = valorToken.valToken.codUser;
    } else {
      iCodUser = 0;
    }
    return iCodUser;
  }

  getCodUserEmpleado(): number {
    let iCodUser: number;
    let datosLogin = this.datosLoginEmpleado();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      iCodUser = valorToken.valToken.codUser;
    } else {
      iCodUser = 0;
    }
    return iCodUser;
  }


  getNombres(): string {
    let sNombresCompletos: string;
    let datosLogin = this.datosLogin();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      sNombresCompletos = valorToken.valToken.nombres;
    } else {
      sNombresCompletos = "";
    }
    return sNombresCompletos;
  }

  getiTipoPerfil(): number {
    let iTipoPerfil: number;
    let datosLogin = this.datosLogin();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      iTipoPerfil = valorToken.valToken.tipoPerfil;
    } else {
      iTipoPerfil = 0;
    }
    return iTipoPerfil;
  }

  getbRta(): boolean {
    let bRtaToken: boolean;
    let datosLogin = this.datosLogin();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      bRtaToken = valorToken.valToken.bRta;
    } else {
      bRtaToken = false;
    }
    return bRtaToken;
  }

  getbRtaEmpleado(): boolean {
    let bRtaToken: boolean;
    let datosLogin = this.datosLoginEmpleado();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      bRtaToken = valorToken.valToken.bRta;
    } else {
      bRtaToken = false;
    }
    return bRtaToken;
  }

  getsMsj(): string {
    let sMsj: string;
    let datosLogin = this.datosLogin();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      sMsj = decodeURIComponent(escape(valorToken.valToken.sMsj));
    } else {
      sMsj = "";
    }
    return sMsj;
  }

  getsMsjEmpleado(): string {
    let sMsj: string;
    let datosLogin = this.datosLoginEmpleado();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      sMsj = decodeURIComponent(escape(valorToken.valToken.sMsj));
    } else {
      sMsj = "";
    }
    return sMsj;
  }

  obtenerNombres() {
    let sNombres: string;
    let datosLogin = this.datosLogin();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      sNombres = valorToken.valToken.nombres;
    } else {
      sNombres = "";
    }
    return sNombres;
  }
  obtenerNombresAdmin() {
    let sNombres: string;
    let datosLogin = this.datosLoginEmpleado();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      sNombres = valorToken.valToken.nombres;
    } else {
      sNombres = "";
    }
    return sNombres;
  }

  obtenerUltimaConexion() {
    let sUltmaConexion: string;
    let datosLogin = this.datosLogin();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      sUltmaConexion = valorToken.valToken.ultimaConexion;
    } else {
      sUltmaConexion = "";
    }
    return sUltmaConexion;
  }
  
  obtenerUltimaConexionAdmin() {
    let sUltmaConexion: string;
    let datosLogin = this.datosLoginEmpleado();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      sUltmaConexion = valorToken.valToken.ultimaConexion;
    } else {
      sUltmaConexion = "";
    }
    return sUltmaConexion;
  }

  obtenerGenero() {
    let sGenero: string;
    let datosLogin = this.datosLogin();
    if (datosLogin != null && datosLogin != "") {
      let valorToken = JSON.parse(datosLogin);
      sGenero = valorToken.valToken.genero;
    } else {
      sGenero = "";
    }
    return sGenero;
  }


  clearStorage() {
    sessionStorage.clear();
    localStorage.clear();
  }

}
