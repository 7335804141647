import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RespuestaTodosUsuarios } from '../../Model/Response/RespuestaTodosUsuarios';
import { RespuestaPersona } from '../../Model/Response/RespuestaPersona';
import { PersonaRe } from '../../Model/DTO/PersonaRegistry';
import { RespuestaGenerica } from '../../Model/Response/RespuestaGenerica';

@Injectable({
  providedIn: 'root'
})
export class CargarTodosUsuariosService {
  public url = environment.URL_SERVICE + '/PersonaPinc';
  public headers = new HttpHeaders(
    {
      'Content-Type': 'application/json;charset=UTF-8',
      //  Authorization: 'Bearer' + sessionStorage.getItem('token')
    }
  );

  constructor(private http: HttpClient) { }

  cargaTodosUsuarios() {
    const path = this.url + '/listaUsuarios';
    return this.http.post<RespuestaTodosUsuarios>(path, { headers: this.headers });
  }

  FindPersonaByIdent(xTipoIdent, xIdent) {
    let path = this.url + '/findPersonaByIdent';
    path += '?xiTipoIdent=' + xTipoIdent;
    path += '&xcIdent=' + xIdent;
    return this.http.post<RespuestaPersona>(path, { headers: this.headers });
  }

  FindPersonaByIdentAndPerfil(xiTipoPerfil: number, xTipoIdent, xIdent) {
    let path = this.url + '/findPersonaByIdent';
    path += '?xiTipoPerfil=' + xiTipoPerfil;
    path += '&xiTipoIdent=' + xTipoIdent;
    path += '&xcIdent=' + xIdent;
    return this.http.post<RespuestaPersona>(path, { headers: this.headers });
  }

  SavePersona(persona: string, xiTipoPerfil: number, xCiudad: number) {
    let path = this.url + '/registrarPersona';
    path += '?xiTipoPerfil=' + xiTipoPerfil;
    path += '&xCiudad=' + xCiudad;
    return this.http.post<RespuestaGenerica>(path, persona, { headers: this.headers });
  }
}
