import { Injectable } from '@angular/core';
import { PagaredigitalService } from '../ConsumoServicios/pagaredigital.service';
import { RespuestaGenerica } from '../../Model/Response/RespuestaGenerica';
import { RespuestaProcesoPagare } from "../../Model/Response/RespuestaProcesoPagare";
import { RespuestaEstadoDocumento } from "../../Model/Response/RespuestaEstadoDocumento";
import { SolicitudPagare } from '../../Model/DTO/solicitud-pagare';
import { ResponseSolicitudPagare } from '../../Model/Response/response-solicitud-pagare';

@Injectable({
  providedIn: 'root'
})
export class PagaredigitalimpService {

  constructor(
    private pagaredigital: PagaredigitalService
  ) { }

  generarPagare(solicitudPagare: SolicitudPagare) {
    return new Promise(resolve => {
      this.pagaredigital.generarPagare(solicitudPagare).subscribe(
        (response: RespuestaProcesoPagare) => {
          resolve(response);
        }
      );
    });
  }

  estadoPagare(xiIdProceso: string) {
    return new Promise(resolve => {
      this.pagaredigital.estadoPagare(xiIdProceso).subscribe(
        (response: RespuestaEstadoDocumento) => {
          resolve(response);
        }
      );
    });
  }

  actualizarEstadoPagare(solicitudPagare: SolicitudPagare) {
    return new Promise(resolve => {
      this.pagaredigital.actualizarEstadoPagare(solicitudPagare).subscribe(
        (response: RespuestaEstadoDocumento) => {
          resolve(response);
        }
      );
    });
  }

  cargarMisPagare(solicitudPagare: SolicitudPagare) {
    return new Promise(resolve => {
      this.pagaredigital.obtenerMisPagares(solicitudPagare).subscribe(
        (responseSolPagare: ResponseSolicitudPagare) => {
          resolve(responseSolPagare);
        }
      )
    });
  }
}
