<mat-dialog-content>
    <img src="./assets/Imagenes/warning.png" alt="" width="150" height="150" *ngIf="data.tipoDialog=='warning'">
    <img src="./assets/Imagenes/success.png" alt="" width="150" height="150" *ngIf="data.tipoDialog=='success'">
    <img src="./assets/Imagenes/error.png" alt="" width="150" height="150" *ngIf="data.tipoDialog=='error'">
    <img src="./assets/Imagenes/Bienvenido.png" alt="" width="150" height="150" *ngIf="data.tipoDialog=='login'">
    <img src="./assets/Imagenes/correcto.png" alt="" width="150" height="150" *ngIf="data.tipoDialog=='correcto'">
    
    
    <p class="titulo">{{data.titulo}}</p>
    
    <p class="txt">{{data.texto}}</p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close class="btn btn-cerrar">Aceptar</button>
</mat-dialog-actions>