<p-dialog [(visible)]="display" [style]="{'background-color':'red'}" *ngIf="contenidoModal!=null">
    <p-header class="headerDialog">
        {{contenidoModal.titulo}}
    </p-header>
    <div class="p-body">
        <img [src]="contenidoModal.ruta" alt="" width="150" height="150">
        <p class="fofo">{{contenidoModal.texto}}</p>
    </div>

    <p-footer>
        <button class="btn btn-primary" (click)="cerrarModal()">OK</button>
    </p-footer>
</p-dialog>

<footer class="footer">
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-3 col-3">
            <div class="centrado">
                <input type="button" value="{{btnAnterior}}" id="anterior" (click)="irAnterior()"
                    class="btn btn-footer-anterior">
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-6">
            <div class="centrado">
                <p class="txtRetroalimentacion">{{texto}}</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-3 col-3">
            <div class="centrado">
                <!--  <input *ngIf="CreditoGuardado=='false'" type="button" value="{{btnSiguiente}}" id="siguiente"
                    (click)="irSiguiente()" class="btn btn-footer-siguiente"> -->

                <!-- <input *ngIf="CreditoGuardado=='true'  &&  Doc =='false' " type="submit" value="{{btnSiguiente}}"
                    id="siguiente" class="btn btn-footer-siguiente">

                <input *ngIf="CreditoGuardado=='false' &&  Doc =='false' " type="submit" value="{{btnSiguiente}}"
                    id="siguiente" class="btn btn-footer-siguiente" (click)="irSiguiente()">


                <input *ngIf="CreditoGuardado=='false' &&  Doc =='true' " type="submit" value="{{btnSiguiente}}"
                    id="siguiente" class="btn btn-footer-siguiente" (click)="irSiguiente()">

                <input *ngIf="CreditoGuardado=='true' &&  Doc =='true' " type="submit" value="{{btnSiguiente}}"
                    id="siguiente" class="btn btn-footer-siguiente" (click)="irSiguiente()"> -->

                <input type="submit" value="{{btnSiguiente}}" id="siguiente" class="btn btn-footer-siguiente" (click)="irSiguiente()">

                <!-- <input *ngIf="idMenu=='token'" type="button" value="{{btnSiguiente}}" class="btn btn-footer-siguiente" (click)="aceptarAutorizacion()"> -->
            </div>
        </div>
    </div>
</footer>